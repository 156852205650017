import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  ActivatedRoute, Router } from '@angular/router';
import { TicketService } from '../services';

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.css']
})
export class AssetInfoComponent implements OnInit {

  assetForm: FormGroup;
  formBuilder: any;
  employeeAssetInfo: any;
  selectedWrong = false;
  selectedWrongAdd = false;
  check_already_upload = false;
  thanks_msg = false;

  constructor(private ticket_service: TicketService, private route: ActivatedRoute) { }

  ngOnInit() {
    var temp_id =  this.route.snapshot.params.id;
    this.assetForm = new FormGroup({
      emp_name : new FormControl("", [Validators.required]),
      emp_id: new FormControl("", [Validators.required]),
      asset_id : new FormControl("", [Validators.required]),
      asset_type : new FormControl("", [Validators.required]),
      serial_number : new FormControl("", [Validators.required]),
      remark : new FormControl(""),
      // additional_remark : new FormControl(""),
      // additional_asset_id : new FormControl(""),
      // additional_asset_type : new FormControl("", [Validators.required]),
      // additional_serial_number : new FormControl("", [Validators.required]),
      // is_desktop : new FormControl("", [Validators.required])
    });

    this.ticket_service.getAssetInfo(temp_id).subscribe((response:any) => {
      this.employeeAssetInfo = response['data'][0];
      if(this.employeeAssetInfo.asset_status == 'true') {
        this.check_already_upload = true;
        this.thanks_msg = false;
        console.log(this.employeeAssetInfo.asset_status,this.check_already_upload, this.employeeAssetInfo);

      }
    })
  }

  onSubmit() {
    if(this.assetForm.invalid){
      return;
    }

    var temp = this.assetForm.value;
    console.log(this.assetForm, temp)
    if(this.selectedWrong == true) {
      temp['new_asset_id'] = this.assetForm.value.asset_id;
      temp['new_asset_type'] = this.assetForm.value.asset_type;
      temp['new_serial_number'] = this.assetForm.value.serial_number;
      temp['asset_id'] = this.employeeAssetInfo.asset_id;
      temp['asset_type'] = this.employeeAssetInfo.asset_type;
      temp['serial_number'] = this.employeeAssetInfo.serial_number;
    }

    if(this.selectedWrongAdd == true) {
      temp['new_additional_asset_id'] = this.assetForm.value.additional_asset_id;
      temp['new_additional_asset_type'] = this.assetForm.value.additional_asset_type;
      temp['new_additional_serial_number'] = this.assetForm.value.additional_serial_number;
      temp['additional_asset_id'] = this.employeeAssetInfo.additional_asset_id;
      temp['additional_asset_type'] = this.employeeAssetInfo.additional_asset_type;
      temp['additional_serial_number'] = this.employeeAssetInfo.additional_serial_number;
    }

    if(this.assetForm.invalid){
      return;
    }

    temp['_id'] = this.employeeAssetInfo._id;
    this.ticket_service.updateAssetInfo(temp).subscribe((res:any) => {
      console.log(res);
      this.check_already_upload = true;
      this.thanks_msg = true;
    })
  }

  updateCheck() {
    if(this.selectedWrong == true) {
      this.selectedWrong = false;
    } else if(this.selectedWrong == false) {
      this.selectedWrong = true;
    }
  }
  updateDesktopCheck() {
    if(this.selectedWrongAdd == true) {
      this.selectedWrongAdd = false;
    } else if(this.selectedWrongAdd == false) {
      this.selectedWrongAdd = true;
    }
  }
}
